"use strict";

import "regulus-oscar";
import Tab from "bootstrap/js/dist/tab";

// Disable the dropdown-toggle on category menu items on large screens.
$('.header-menu .dropdown').on('click', function(e) {
    if ($(window).width() >= 992) {
        e.stopPropagation();
    }
});

$(function() {
    // The product review add page (linked from the "Add your review" button)
    if (window.location.hash === '#addreview') {
        // Go to the product-info-tabs section, and activate the reviews tab
        window.location.hash = '#product-info-tabs';
        new Tab(document.querySelector('#reviews-tab')).show();
    }
});
